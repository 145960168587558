import React from "react";
import '../styles/about.css';
import Header from "../components/header";
import Featured from "../components/featured";
import GalleryAd from "../components/gallery-ad";
import Footer from "../components/footer";
import Info from "../components/info";
import More from "../components/info-cards";

function About() {
    return <div className="about-page">
        <Info />
        <More />
        <Featured />
        <GalleryAd />
        <Footer />
    </div>
}

export default About;