import './App.css';
import Nav from "./components/nav";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from "./pages/home.js";
import Artists from "./pages/services.js";
import NA from "./pages/NA";
import About from "./pages/about.js";


function App() {
 {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route exact path="/" element ={<Home/>} />
        <Route exact path="/services" element ={<Artists/>} />
        <Route exact path="/about" element ={<About/>} />
        <Route exact path="*" element ={<NA/>} />
      </Routes>
    </Router>
  );
}
}
export default App;
