import '../styles/info.css'
import Header_IMG from "../images/fantomgallery-home.png";
import React from 'react';

function Info() {
    return <div className="page-container">
        <div className="page-section">
        <h1>What is Eyepeeling?</h1>
        <p>Eyepeeling is a ceative studio with over 10 years of experience launching over 50 campaigns. We work with individuals, artists, and companies to bring their visions to life.</p>
        <h1>What is a digital collectiable?</h1>
        <p>There is a new way to own and trade digital assets. NFTs are a great way to build and reward a community.</p>

        </div>
    </div>
}

export default Info;