import React from "react";
import '../styles/home.css';
import Header from "../components/header";
import Featured from "../components/featured";
import GalleryAd from "../components/gallery-ad";
import Footer from "../components/footer";
import More from "../components/info-cards";
import Services from "../components/services";

import Press from "../components/press";

function Home() {
    return <div className="home-page">
        <Header />
        
        <Press />
        <Featured />
        <Services />
        <GalleryAd />
        <Footer />
    </div>
}

export default Home;