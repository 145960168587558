import React from "react";
import '../styles/galleries.css';
import '../styles/header.css';
import Card from "../components/card.js";
import GalleryAd from "../components/gallery-ad";
import Footer from "../components/footer.js";

import Funeral from "../artists/funeral.js";
import Elle from "../artists/elle.js";
import Czepta from "../artists/czepta.js";
import Limit from "../artists/limit.js";
import Sellek from "../artists/sellek.js";
import Cosmic from "../artists/cosmic.js";
import Teens from "../artists/teens.js";

import network from "../images/network.png";

function Artists() {
return <div>
        <div className="page">
        <div className="header-container">
        <div className="header-content">
        <h1>Featured Artists</h1>
        <p>A collective of vetted artists you can trust. Our community votes on what artists are promoted on our platfrom.</p>
        </div>
        <div className="network-img">
        <img src = {network} />
        </div>
    </div>
        <div className="artists">

            <Cosmic />
            <Czepta />
            <Elle />
            <Funeral />
            <Limit />
            <Sellek />
            <Teens />
            <GalleryAd />
            <Footer />
        </div>
        </div>
    </div>
}

export default Artists;