import '../styles/header.css'
import Header_IMG from "../images/desk1.gif";
import React from 'react';
import { Link } from "react-router-dom";


function Header() {
    return <div className="header-container">
        <div className="header-content">
        <h1>Your Creative Partner.</h1>
        <p>Building unique brands and digital assets that bring your ideas to life.</p>
        <a href="https://calendly.com/eyepeeling"><button>Let's talk.</button></a>
        </div>
        <div className="header-content">
        <img src={Header_IMG}/>
        </div>
    </div>
}

export default Header