import React from 'react'
import '../styles/featured.css'
import { Link } from "react-router-dom";
import Flier from "../images/Eyepeeling-logo.png";

function Featured() {
    return <div className="featured-container">
    <div className="featured-content">
    <h1>Logos</h1>
    <p>Create a unique brand identity that builds lasting impressions and loyal communities.</p>
    </div>
    <div className="featured-content">
    <h1>Websites</h1>
    <p>Build a digital storefront for your brand with a custom front-end you can be proud of.</p>
    </div>
    <div className="featured-content">
    <h1>Digital Collectibles</h1>
    <p>Discover the world of Web3 and build loyalty around your brand with NFTs.</p>
    </div>
    <div className="featured-content">
    <h1>Virtual Spaces</h1>
    <p>Bring your brand to life with a website that delivers an emersive experience.</p>
    </div>
    </div>
}

export default Featured