import React from 'react'
import '../styles/services.css'
import { Link } from "react-router-dom";

import snapshot from "../images/snapshot.png";
import gitbook from "../images/gitbook.png";
import discord_img from "../images/discord.png";


function Services() {
    return <div className="info-cards">
        <div className = "card">
        <div className='card-title'>
        <h1>Advising</h1>
        </div>
        <div className='card-body'>
        <p>Navigate the world of NFTs, Web3, and the Metaverse.</p>
        </div>
        <div className = 'card-logo'>
        <a href="https://discord.gg/KRpT7UFgfB"><img src={discord_img} alt="discord"/></a>
        </div>
        </div>
        <div className = "card">
        <div className='card-title'>
        <h1>Branding</h1>
        </div>
        <div className='card-body'>
        <p>Build an iconic identity that can stand the test of time.</p>
        </div>
        <div className = 'card-logo'>
        <a href="https://snapshot.org/#/fantomgallery.eth" target="_blank" rel="noopener noreferrer"><img src={snapshot} alt="snapshot"/></a>
        </div>
        </div>
        <div className = "card">
        <div className='card-title'>
        <h1>Content</h1>
        </div>
        <div className='card-body'>
        <p>Build a culture through visual storytelling.</p>
        </div>
        <div className = 'card-logo'>
        <a href="https://fantomgallery.gitbook.io/fantom-gallery/" target="_blank" rel="noopener noreferrer"><img src={gitbook} alt="gitbook"/></a>
        </div>
        </div>
    </div>
}

export default Services