import React from 'react'
import '../styles/nav.css'
import { Link } from "react-router-dom";
import Logo from "../images/eyepeeling-text-logo.png";

function Nav() {
    return <div className="navbar">
    <div className="navbar-content">
        <div className = "left-nav">
        <img src={Logo} />
        </div>
        <div className = "right-nav">
            <div className ="nav-links">
           
                <a href="https://calendly.com/eyepeeling"><button>Let's talk.</button></a>
            </div>
        </div>
    </div>
    </div>
}

export default Nav