import '../styles/gallery-ad.css';
import Logo from "../images/gallery.png";
import React from 'react';
import { Link } from "react-router-dom";


function GalleryAd() {
return <div className="ad-section">
    <div>
    <div>
        <div>
        <a href="https://calendly.com/eyepeeling" target="_blank" rel="noopener noreferrer">
            <div className="ad-card">
            <a href to="https://calendly.com/eyepeeling"><button>Take my card.</button></a>
            </div>
            </a>
        </div>
    </div>
    </div>
</div>
}
export default GalleryAd