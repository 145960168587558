import React from 'react'
import '../styles/press.css'
import { Link } from "react-router-dom";
import Flier from "../images/Eyepeeling-logo.png";
import APP from "../images/app-logo.png";
import NEWST from "../images/news12-logo.png";
import Benzinga from "../images/benzinga.png";
import NFTNYC from "../images/nft-nyc.png";
import Apple from "../images/apple.png";

function Press() {
    return <div className="press-container">
    <div className="press-content">
    <img src={Apple}/>
    </div>
    <div className="press-content">
    <img src={Benzinga}/>
    </div>
    <div className="press-content">
    <img src={NFTNYC}/>
    </div>

    </div>
}

export default Press