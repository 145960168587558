import '../styles/footer.css';
import React from 'react';
import discord_img from "../images/discord.png";
import twitter_img from "../images/twitter.png";
import candle from "../images/candle.png";
import gitbook from "../images/gitbook.png";
import snapshot from "../images/snapshot.png";

function Footer() {
return <div className="footer">
<div className="social-links">
   <p>eyepeeling ©2022</p>
  </div>
</div>
}
export default Footer